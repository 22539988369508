import { Grade } from "../product/product.types";

export interface User {
    id: string;
    firstName?: string;
    lastName?: string;
    role: string;
    type: UserType;
    email: string;
    avatar?: string;
    status?: string;
    phone?: string;
    occupation?: string;

    verifiedBy?: string;
    verifiedOn?: Date;
    updatedOn?: Date;
    createdOn?: Date;
    lastLoginOn?: Date;
    lastPasswordResetOn?: Date;

    // to be completed later
    children?: Child[];
    cards?: Card[];
    notifications?: Notifications;
    businessDetails?: BusinessDetails;
    statistics?: Statistics;
}

export interface Statistics {
  [key: string]: OrderStatistics;
}

export interface OrderStatistics {
  firstOrderDate: string; // Use Date if parsing
  lastOrderDate: string;
  productCount: number;
  orderCount: number;
}

export interface Card {
  number: string;
  isDefault: boolean;
  expirationDate: string;
  createdOn: Date;
  billingDetails: BillingDetails;
  type: CardType;
}

export interface BillingDetails {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  address: string;
}

export interface BusinessDetails {
    name: string;
    identifier: string;
    regNumber: string;
    city: string;
    district: string;
    address: string;
    isVatPayer: boolean;
    isEnabled: boolean;
}

export interface Child {
  id?: string;
  avatar?: string;
  firstName?: string;
  lastName?: string;
  school?: string;
  interests?: string[];
  birthday?: string;
  grade?: Grade;
  recommendationsIds?: string[];
}

export interface UserResponse {
    total: number;
    pageNumber: number;
    pageSize: number;
    data: User[];
  }

  export interface Notifications {
    offers: boolean;
    upcomingEvents: boolean;
    personalizedOffers: boolean;
  }

  export const Notifications = {
    offers: 'oferte',
    upcomingEvents: 'evenimente',
    personalizedOffers: 'oferte personalizate'
  };

  export enum UserType {
    parent = 'parent',
    teacher = 'teacher',
    order_operator = 'order_operator'
  }

  export enum CardType {
    other = 'other',
    visa = 'visa',
    mastercard = 'mastercard',
    maestro = 'maestro'
  }
